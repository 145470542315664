import { useCallback, useMemo, useState } from "react";
import * as Bananas from "bananas-commerce-admin";

import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Button, TableBody } from "@mui/material";

import ErrorCard from "@/extensions/klarahill/components/ErrorCard";
import * as styles from "@/extensions/klarahill/pages/case/shared";
import { Case, Gift } from "@/extensions/klarahill/types";

import GiftRow from "./Row";

export const Gifts: React.FC<styles.CaseTabProps> = ({ case: c }) => {
  const api = Bananas.useApi();
  const [currentCase, setCurrentCase] = useState<Case>(c);

  const [
    gifts,
    downloadableGiftIds,
    downloadableNewGiftIds,
    downloadableGiftsURL,
    downloadableNewGiftsURL,
  ] = useMemo(() => {
    const operation = api.operations["case.case:get-gift-pdf"];
    if (!operation) {
      throw new Error('No action found for "case.case:get-gift-pdf"');
    }

    const gifts = currentCase.gifts
      .sort((a: Gift, b: Gift) => b.id - a.id)
      .map((gift) => {
        if (gift.status !== "captured" && gift.status !== "disbursed") {
          return { ...gift, certificateUrl: null };
        }

        return {
          ...gift,
          certificateUrl: operation.url({ params: { gift_ids: [gift.id] } }),
        };
      });

    let downloadableGiftIds,
      downloadableNewGiftIds,
      downloadableGiftsURL,
      downloadableNewGiftsURL;

    if (gifts.length > 1) {
      const downloadableGifts = gifts.filter(
        (gift) => gift.certificateUrl != null,
      );
      downloadableGiftIds = downloadableGifts.map((gift) => gift.id);
      downloadableNewGiftIds = downloadableGifts
        .filter((gift) => gift.certificate_download_date == null)
        .map((gift) => gift.id);

      downloadableGiftsURL = operation.url({
        params: { gift_ids: downloadableGiftIds },
      }).href;

      downloadableNewGiftsURL =
        downloadableNewGiftIds.length > 0
          ? operation.url({ params: { gift_ids: downloadableNewGiftIds } }).href
          : null;
    }

    return [
      gifts,
      downloadableGiftIds ?? [],
      downloadableNewGiftIds ?? [],
      downloadableGiftsURL,
      downloadableNewGiftsURL,
    ];
  }, [currentCase]);

  const downloadGiftReports = useCallback(() => {
    const operation = api.operations["case.case:get-case-gifts-pdf"];
    if (!operation) {
      throw new Error('No action found for "case.case:get-case-gifts-pdf"');
    }

    return operation.url({ params: { case_id: currentCase.id } }).href;
  }, [currentCase]);

  const refreshGiftStatus = useCallback(
    (...giftIds: number[]) =>
      () => {
        setCurrentCase((currentCase) => ({
          ...currentCase,
          gifts: currentCase.gifts.map((gift) =>
            giftIds.includes(gift.id)
              ? {
                  ...gift,
                  certificate_download_date: new Date().toISOString(),
                }
              : gift,
          ),
        }));

        setTimeout(async () => {
          const operation = api.operations["case.case:retrieve"];
          if (!operation) {
            throw new Error('No action found for "case.case:retrieve"');
          }

          const response = await operation.call({
            params: { case_id: currentCase.id },
          });
          setCurrentCase(await response.json());
        }, 5000);

        return true;
      },
    [api, currentCase, setCurrentCase],
  );

  const hasDownloadableGifts = useMemo(
    () =>
      Boolean(
        downloadableGiftsURL != null &&
          gifts.some(({ certificateUrl }) => certificateUrl != null),
      ),
    [gifts, downloadableGiftsURL],
  );

  return (
    <Bananas.ContentWrapperWithActionBar>
      <Bananas.Content layout="fullWidth">
        {gifts && gifts.length === 0 ? (
          <ErrorCard errorMessage="Det finns inga minnesgåvor för det här begravningsärendet." />
        ) : (
          <Bananas.TableCard>
            <Bananas.Table count={gifts?.length ?? 0}>
              <Bananas.TableHead>
                <Bananas.TableHeading>Status</Bananas.TableHeading>
                <Bananas.TableHeading>Namn</Bananas.TableHeading>
                <Bananas.TableHeading>Mottagare</Bananas.TableHeading>
                <Bananas.TableHeading align="right" sx={{ pr: 3 }}>
                  Gåvobevis
                </Bananas.TableHeading>
              </Bananas.TableHead>

              <TableBody>
                {gifts?.map((gift, i) => (
                  <GiftRow
                    key={i}
                    gift={gift}
                    onClick={refreshGiftStatus(gift.id)}
                  />
                ))}
              </TableBody>
            </Bananas.Table>
          </Bananas.TableCard>
        )}
      </Bananas.Content>

      {hasDownloadableGifts && (
        <Bananas.ActionBar>
          <Button href={downloadGiftReports()} variant="outlined">
            Ladda ned hälsningar
          </Button>

          <Button
            href={downloadableGiftsURL}
            variant="outlined"
            onClick={refreshGiftStatus(...downloadableGiftIds)}
          >
            Ladda ned alla gåvobevis
          </Button>

          <Button
            color="primary"
            disabled={downloadableNewGiftsURL == null}
            href={downloadableNewGiftsURL ?? ""}
            startIcon={<SaveAltOutlinedIcon />}
            variant="contained"
            onClick={refreshGiftStatus(...downloadableNewGiftIds)}
          >
            Ladda ned nya gåvobevis
          </Button>
        </Bananas.ActionBar>
      )}
    </Bananas.ContentWrapperWithActionBar>
  );
};
